// src/index.js
import { validateKey, setBaseUrl } from './widget/shared/helpers';

if (module.hot) {
    module.hot.accept();
}

const script = Array.from(document.scripts).find(s => s.src.includes('bundle.js'));
const key = script.getAttribute('data-key');
const tenant = script.getAttribute('data-tenant');

(async function() {
    try {
      setBaseUrl();
      const isValid = await validateKey(key, tenant);

      if (isValid) {
        import('./widget/home-search/hs-widget');
        import('./widget/home-valuation/hv-widget');
        import('./widget/market-trend/mt-widget');
        import('./widget/agent-search/as-widget');
      } else {
        console.log('Invalid key. Widgets not available.');
      }
    } catch (error) {
      console.error('Error during key validation:', error);
    }
  })();