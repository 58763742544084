// validation.js

let key = null;
let tenantId = null;
let baseUrl = '/';
let baseApiUrl = 'https://api.local.myhomeadvantage.com:44301/';

const headers = new Headers({
    'Abp.TenantId': tenantId,
    'X-API-Key': key,
    'User-Agent': 'WidgetClient'
});

const setBaseUrl = () => {
    const currentUrl = window.location.host;

    if (!currentUrl.includes('localhost')) {
        baseUrl = 'https://assets.myhomeadvantage.com/';
        baseApiUrl = 'https://myha-api-prod.azurewebsites.net/';
    }
};

const validateKey = async (inputKey, inputTenantId) => {
    try {
        const endpoint = 'api/services/app/ApiKeys/ValidateApiKey';
        const queryParams = { apiKey: inputKey };
        const url = `${baseApiUrl}${endpoint}?${serializeParams(queryParams)}`;

        const response = await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'User-Agent': 'WidgetClient',
                'Abp.TenantId': inputTenantId,
                'X-API-Key': inputKey
            })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data.result) {
            key = inputKey;
            tenantId = inputTenantId;
            headers.set('Abp.TenantId', tenantId);
            headers.set('X-API-Key', key);
            return true;
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
    return false;
};

const serializeParams = (params) => {
    return Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
};

const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `${r}, ${g}, ${b}`;
};

export function closeAllLists(widget) {
    const autocompleteItems = widget.shadowRoot.getElementById('autocomplete-items');
    autocompleteItems.style.display = 'none';
    autocompleteItems.innerHTML = '';
}

export function setColors(widget) {
    widget.shadowRoot.host.style.setProperty('--ha-primary', widget.primaryColor);
    widget.shadowRoot.host.style.setProperty('--ha-primary-rgb', hexToRgb(widget.primaryColor));

    widget.shadowRoot.host.style.setProperty('--ha-secondary', widget.secondaryColor);
    widget.shadowRoot.host.style.setProperty('--ha-secondary-rgb', hexToRgb(widget.secondaryColor));

    if (widget.fontUrl) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = widget.fontUrl;
        widget.shadowRoot.appendChild(link);
            
        widget.shadowRoot.host.style.setProperty('--bs-font-sans-serif', widget.fontFamily);
        widget.shadowRoot.host.style.setProperty('--bs-body-font-family', widget.fontFamily);
    }
}

// Export the functions and variables you want to use in other modules
export {
    validateKey,
    serializeParams,
    setBaseUrl,
    hexToRgb,
    getBaseUrl,
    getBaseApiUrl,
    getHeaders,
    getKey,
    getTenantId
};

// Additional getters for baseUrl, baseApiUrl, headers, key, and tenantId
const getBaseUrl = () => baseUrl;
const getBaseApiUrl = () => baseApiUrl;
const getHeaders = () => headers;
const getKey = () => key;
const getTenantId = () => tenantId;
